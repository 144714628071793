<template>
  <section>
    <div>
      <header>
        <el-row>
          <el-col :md="3" :lg="5">
            <el-button plain @click="goBack"
              ><i class="el-icon-back"> Back</i></el-button
            >
          </el-col>
          <el-col :md="21" :lg="19">
            <el-steps :active="active" finish-status="success" :space="400">
              <el-step
                style="step-class"
                title="Upload file or Ask AI"
                @click.native="handleActiveChange(0)"
              ></el-step>
              <el-step
                style="step-class"
                title="Map Fields"
                @click.native="handleActiveChange(1)"
              ></el-step>
              <el-step
                style="step-class"
                title="Workspace Settings"
                @click.native="handleActiveChange(2)"
              ></el-step>
              <el-step
                style="step-class"
                title="Review & Import"
                @click.native="handleActiveChange(3)"
              ></el-step>
            </el-steps>
          </el-col>
        </el-row>
      </header>
      <hr />
      <section style="flex-grow: 1; overflow-y: auto">
        <div
          style="text-align: center"
          v-if="this.active === 0"
          v-loading="tableLoading"
        >
          <el-scrollbar
            v-if="cardLoading"
            wrap-style="max-height: 80%; overflow-x: auto; overflow-y: auto; scrollbar-width: thin;"
          >
            <loadingComponentVue :features="[]"></loadingComponentVue>
          </el-scrollbar>
          <el-scrollbar
            v-else
            wrap-style="max-height: 80%; overflow-x: auto; overflow-y: auto; scrollbar-width: thin;"
          >
            <div>
              <el-radio-group v-model="creationType" size="mini">
                <el-radio-button label="Ask AI"></el-radio-button>
                <el-radio-button label="Upload File"></el-radio-button>
              </el-radio-group>
            </div>
            <div v-if="creationType == 'Upload File'" class="mt-2">
              <el-upload
                v-if="isExcelFileuploaded === false"
                class="upload-file-el-upload"
                drag
                name="logo"
                :on-change="uploadExcelfile"
                action
                :show-file-list="false"
                :auto-upload="false"
                accept=".xlsx"
              >
                <div class="upload-file">
                  <div class="icon2 py-2">
                    <img
                      src="@/assets/img/icons/upload-file.svg"
                      alt="Upload File"
                      height="40"
                      width="100"
                      class="upload-icon"
                    />
                  </div>
                  <p class="text-dark fs-9 mb-0">
                    Drag &amp; Drop Excel file here
                  </p>
                  <p class="fs-9 mb-0" style="color: #babdc2">OR</p>
                  <button
                    class="match_upload_button mt-0"
                    style="padding: 6px 10px !important"
                  >
                    Import Excel
                  </button>
                </div>
              </el-upload>
              <div v-else-if="isExcelFileuploaded === true" class="mb-5">
                <img src="@/assets/img/mapped_file_uploaded_success.svg" />
                <br /><br />
                <h1>File Uploaded Successfully</h1>
              </div>
              <br />

              <div
                class="d-flex flex-row justify-content-center file-upload"
                v-if="isExcelFileuploaded === true"
              >
                <div class="uploaded-file-display pl-1 pr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="green"
                    class="bi bi-check2"
                    viewBox="0 0 16 16"
                    style="font-weight: 600px !important"
                  >
                    <path
                      d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                  <img src="@/assets/img/entity_dots.svg" />
                  <img src="@/assets/img/entity_excel.svg" />
                  <p style="margin: auto auto auto 5px">{{ excelFile.name }}</p>
                  <div class="flex-grow-1"></div>
                  <div style="cursor: pointer">
                    <a @click="removeExcelFile"
                      ><i class="el-icon-circle-close"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="class-ai mt-2">
                <el-card
                  class="box-card"
                  shadow="never"
                  v-if="!responseCaptured"
                >
                  <img src="@/assets/img/Describe.svg" />
                  <p class="mt-10">Describe Your Application</p>
                  <el-divider></el-divider>
                  <p class="mb-2">
                    Tell us what your application should do in a few words
                  </p>
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="Please Enter Application Name with Atleast 15 Characters"
                    v-model="application_prompt"
                    class="input-field"
                  ></el-input>
                  <div v-if="gotErrorResponse">
                    <span class="warning font-class">{{
                      errorResponseMessage ||
                      "Provided data is not sufficient to create your application"
                    }}</span>
                  </div>
                  <div class="generate-button">
                    <el-button
                      type="primary"
                      :disabled="application_prompt.length < 15"
                      @click="generateResponse()"
                      size="medium"
                      style="width: 100%"
                      >{{
                        getIsMobile ? "Generate" : "Generate Application"
                      }}</el-button
                    >
                  </div>
                </el-card>
                <div v-else>
                  <div class="card-container mt-1">
                    <div
                      class="single-table"
                      v-for="table of tablesData"
                      :key="table.table_name"
                    >
                      <el-card class="box-card2 mb-1 card" shadow="never">
                        <h4>{{ table.table_name }}</h4>
                        <div class="mt-1">
                          <el-table
                            height="350"
                            :data="table.attributes"
                            border
                            size="mini"
                          >
                            <el-table-column
                              prop="name"
                              label="Name"
                              width="160"
                            ></el-table-column>
                            <el-table-column
                              prop="type"
                              label="Type"
                              width="178"
                            >
                              <template slot-scope="scope">
                                <p>
                                  {{
                                    fieldInputTypeKeyLabels[scope.row.type] ||
                                    scope.row.type
                                  }}
                                </p>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-card>
                    </div>
                  </div>
                </div>
              </div>
              <div class="message-ex" v-if="!responseCaptured">
                (Ex:Create an application that allows employees to easily access
                all their information, including logins, timesheets, payslips,
                holiday lists, benefits, and other relevant details.)
              </div>
            </div>
          </el-scrollbar>
          <div v-if="tableLoading || groupLoading" class="loading-container">
            <img :src="iconSrc" alt="Loading..." />
            <p>Generating your entity tables, please wait...</p>
          </div>
        </div>
      </section>
      <section v-if="active === 1" class="sections">
        <el-tabs v-model="activeNameForApplicationImport">
          <el-tab-pane
            v-for="tab in Object.keys(jsonDataOfExcel)"
            :label="tab"
            :key="tab"
            :name="tab"
          >
            <el-row>
              <div style="display: flex; gap: 25px">
                <div class="d-flex-col">
                  <span class="mr-1">Select ENTITY Type </span>
                  <span>
                    <el-select
                      v-if="showEntityTypes"
                      v-model="
                        excelFileHeadersApplication[
                          activeNameForApplicationImport
                        ]['entity_type']
                      "
                      @change="
                        addStandardTemp(
                          excelFileHeadersApplication[
                            activeNameForApplicationImport
                          ]['entity_type']
                        )
                      "
                      clearable
                      size="small"
                    >
                      <el-option
                        v-for="option in ['INDIVIDUAL', 'GENERAL', 'BUSINESS']"
                        :key="option"
                        :value="option"
                        :label="option"
                      >
                        {{ option }}

                        <i
                          v-if="option === 'BUSINESS'"
                          class="el-icon-star-on"
                        ></i>
                        <el-popover
                          placement="top-start"
                          trigger="hover"
                          width="371"
                          content="An organization with multiple contacts. (Ex: Companies)"
                        >
                          <i
                            v-if="option === 'BUSINESS'"
                            class="el-icon-info"
                            slot="reference"
                          ></i>
                        </el-popover>
                        <i
                          v-if="option === 'INDIVIDUAL'"
                          class="el-icon-user"
                        ></i>
                        <el-popover
                          placement="top-start"
                          trigger="hover"
                          width="336"
                          content="A person with their individual data. (Ex: Employee)"
                        >
                          <i
                            v-if="option === 'INDIVIDUAL'"
                            class="el-icon-info"
                            slot="reference"
                          ></i>
                        </el-popover>
                        <i
                          v-if="option === 'GENERAL'"
                          class="el-icon-tickets"
                        ></i>
                        <el-popover
                          placement="top-start"
                          trigger="hover"
                          width="555"
                          content="Any entity recognized with data, neither a person nor an organization. (Ex: Invoice)."
                        >
                          <i
                            v-if="option === 'GENERAL'"
                            class="el-icon-info"
                            slot="reference"
                          ></i>
                        </el-popover>
                      </el-option>
                    </el-select>
                  </span>
                </div>
                <div class="d-flex-col">
                  <span>
                    Groups
                    <el-tooltip content="add new group">
                      <i
                        class="el-icon-circle-plus icon-class ml-05"
                        @click="addGroupPopUP"
                        style="color: var(--primary-color)"
                      >
                      </i>
                    </el-tooltip>
                    <el-tooltip content="update groups in all entities">
                      <i
                        class="el-icon-refresh icon-class ml-05"
                        @click="updateGroupInAllEntities"
                        style="color: var(--primary-color)"
                      >
                      </i> </el-tooltip
                  ></span>
                  <span>
                    <el-select
                      v-model="
                        excelFileHeadersApplication[
                          activeNameForApplicationImport
                        ]['groups']
                      "
                      multiple
                      v-if="showGroups"
                      @change="handleGroupChange"
                      :collapse-tags="true"
                      :loading="groupLoading"
                      size="small"
                    >
                      <el-option
                        v-for="option in groups"
                        :key="option._id"
                        :label="option.name"
                        :value="option._id"
                      >
                      </el-option>
                    </el-select>
                  </span>
                </div>
                <div class="d-flex-col">
                  <span> Choose Icon</span>
                  <span>
                    <div
                      style="cursor: pointer"
                      class="ml-3 icon2"
                      @click="iconPickerdialogVisible = true"
                    >
                      <icons
                        v-model="
                          excelFileHeadersApplication[
                            activeNameForApplicationImport
                          ]['entity_symbol']
                        "
                        :iconName="
                          excelFileHeadersApplication[
                            activeNameForApplicationImport
                          ]['entity_symbol']
                        "
                      ></icons></div
                  ></span>
                </div>
              </div>
            </el-row>
            <br />
            <el-row>
              <el-scrollbar wrap-style="max-height:55vh;" ref="configureScroll">
                <el-table
                  :data="
                    excelFileHeadersApplication[activeNameForApplicationImport][
                      'columnHeaders'
                    ]
                  "
                  border
                  style="width: 100%"
                  v-loading="tableLoading"
                >
                  <el-table-column label="Fields in File">
                    <template slot-scope="scope">
                      <p>{{ scope.row.excel_field }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Form templates" width="250px">
                    <template slot-scope="scope">
                      <div class="d-flex flex-row">
                        <el-select
                          clearable
                          filterable
                          v-model="scope.row.template_id"
                          placeholder="Select form template"
                          @change="validateSelectedRow(scope.row)"
                        >
                          <el-option
                            v-for="temp in excelFileHeadersApplication[
                              activeNameForApplicationImport
                            ]['formTemplates']"
                            :key="temp._id"
                            :label="temp.name"
                            :value="temp._id"
                          >
                            <div v-if="temp && temp.type == 'STANDARD'">
                              <span>{{ temp.name }}</span>
                              <span
                                style="color: #f754a2; font-weight: 800"
                                class="ml-2"
                                >STANDARD</span
                              >
                            </div>
                            <div v-else>
                              {{ temp.name }}
                            </div>
                          </el-option>
                        </el-select>
                        <i
                          class="el-icon-circle-plus mt-10"
                          style="color: var(--primary-color); margin-left: 10px"
                          @click="ShowTempDialog(scope.$index)"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Form fields" width="375px">
                    <template slot-scope="scope">
                      <div class="d-flex flex-row">
                        <el-select
                          v-if="
                            scope.row.template_id &&
                            scope.row.template_id.includes('newTemplate')
                          "
                          v-model="scope.row.inputType"
                          placeholder="Select field"
                          :disabled="scope.row.inputType == 'ENTITY_TABLE'"
                          @change="validateInputFields(scope.row)"
                          clearable
                          style="width: 100%"
                        >
                          <el-option
                            v-for="field in allFormFIelds"
                            :key="field.key"
                            :label="field.name"
                            :value="field.key"
                          >
                            <div class="field-icon-container">
                              <img
                                :src="
                                  require('@/assets/img/fields/' +
                                    field.img_url)
                                "
                                style="width: 20px; padding-right: 10px"
                              />
                              {{ field.name }}
                            </div>
                          </el-option>
                        </el-select>
                        <el-select
                          v-else
                          v-model="scope.row.template_filed_id"
                          placeholder="Select field"
                          filterable
                          clearable
                        >
                          <el-option
                            v-for="(field, index) in getTemplateFields(
                              scope.row
                            )"
                            :key="field._id + index + field.label"
                            :label="field.label"
                            :value="field.key"
                            :disabled="checkIsFieldSelected(field)"
                          >
                            <div v-if="field && field.inputType == 'ENTITY'">
                              <span>{{ field.label }}</span>
                              <span
                                style="color: #f754a2; font-weight: 800"
                                class="ml-2"
                                >ENTITY</span
                              >
                            </div>
                            <div v-else>
                              {{ field.label }}
                            </div>
                          </el-option>
                        </el-select>
                        <el-select
                          v-if="
                            scope.row.inputType == 'ENTITY' &&
                            scope.row.template_id &&
                            scope.row.template_id.includes('newTemplate')
                          "
                          v-model="scope.row.entity_id"
                          placeholder="Select entity"
                          filterable
                          class="ml-2"
                        >
                          <el-option
                            v-for="field in excelFileHeadersApplication[
                              activeNameForApplicationImport
                            ]['available_entities']"
                            :key="field.name"
                            :label="field.name"
                            :value="field.value"
                            :disabled="checkEntityIsSelected(field)"
                          >
                            {{ field["name"] }}
                            <span
                              v-if="
                                field &&
                                field.value &&
                                field.value.split('#')[1] == 'new'
                              "
                              style="color: #f754a2; font-weight: 800"
                              class="ml-2"
                              >NEW</span
                            >
                          </el-option>
                        </el-select>
                        <el-select
                          v-else-if="
                            scope.row.inputType == 'CURRENCY' &&
                            scope.row.template_id &&
                            scope.row.template_id.includes('newTemplate')
                          "
                          v-model="scope.row.currency_type"
                          placeholder="Select Currency Type"
                          class="ml-05"
                        >
                          <el-option
                            v-for="field in currencyTypes"
                            :key="field"
                            :label="field"
                            :value="field"
                          >
                          </el-option>
                        </el-select>
                        <div
                          class="d-flex"
                          style="flex-direction: row"
                          v-else-if="
                            scope.row.inputType == 'AUTO_INCREMENT_NUMBER' &&
                            scope.row.template_id &&
                            scope.row.template_id.includes('newTemplate')
                          "
                        >
                          <div>
                            <el-input
                              v-model="scope.row.auto_increment_prefix"
                              class="ml-05"
                              placeholder="Prefix"
                              style="width: 100px"
                            >
                            </el-input>
                          </div>
                          <div>
                            <el-input
                              v-model="scope.row.auto_increment_starting_value"
                              type="number"
                              class="ml-05"
                              style="width: 100px"
                              placeholder="Starting Number"
                              @input="handleAutoIncrementChange(scope.row)"
                            >
                            </el-input>
                          </div>
                        </div>
                        <el-select
                          v-else-if="
                            scope.row.inputType == 'ENTITY_TABLE' &&
                            scope.row.template_id &&
                            scope.row.template_id.includes('newTemplate')
                          "
                          v-model="scope.row.entity_table_parent"
                          :disabled="true"
                          placeholder="Select ENTITY"
                          class="ml-05"
                        >
                          <el-option
                            v-for="field in getOtherEntityNames(scope.row.name)"
                            :key="field"
                            :label="field"
                            :value="field"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Is primary field">
                    <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.isPrimaryField">
                      </el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column label="Is required field">
                    <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.isRequired">
                      </el-checkbox>
                    </template>
                  </el-table-column>
                </el-table>
              </el-scrollbar>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </section>
      <section v-if="active === 2" class="sections">
        <div
          :style="{ width: containerWidth }"
          v-if="checkIsNewWorkspace()"
          class="d-flex"
        >
          <div class="collapse-items">
            <el-button class="mb-10" size="mini" @click="resetCompanyForm()"
              >Reset <i class="el-icon-refresh"></i
            ></el-button>
            <el-collapse
              v-model="activeMenuProfile"
              style="color: #677788; width: 80%"
              class="items-container"
            >
              <el-collapse-item
                title="Company information"
                name="1"
                class="items"
              >
                <div class="ml-1">
                  <div class="input-in-profile mt-1">Company Logo</div>
                  <div class="image-container">
                    <img
                      v-if="this.imgData"
                      :src="this.imgData"
                      height="100"
                      width="260"
                      class="avatar mb-1"
                    />

                    <img
                      v-else
                      src="@/assets/kodefast-Logo-Black.png"
                      height="100"
                      width="270"
                      class="avatar"
                    />
                  </div>
                  <el-button type="primary" @click="openLogoDialog"
                    >Change Logo</el-button
                  >
                  <div class="mt-1 d-flex-col input-in-profile">
                    <span>Enter Your Company Name</span>
                    <el-input
                      v-model="company_form.name"
                      style="width: 70%"
                      disabled
                    ></el-input>
                  </div>
                  <div class="mt-1 d-flex-col input-in-profile">
                    <span>About your company</span>
                    <el-input
                      v-model="company_form.about_company_text"
                      type="textarea"
                      rows="4"
                      style="width: 70%"
                    ></el-input>
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Colors" name="2" class="items">
                <div class="colors-ProfileSettings ml-1">
                  <div class="color-item">
                    <span>Theme/Brand Color</span>
                    <span style="margin-left: auto">{{
                      company_form.dialog_header_color
                    }}</span>
                    <el-color-picker
                      v-model="company_form.dialog_header_color"
                      size="small"
                    ></el-color-picker>
                  </div>
                  <div class="color-item">
                    <span>App Header</span>
                    <span style="margin-left: auto">{{
                      company_form.menubackground
                    }}</span>
                    <el-color-picker
                      v-model="company_form.menubackground"
                      size="small"
                    ></el-color-picker>
                  </div>
                  <div class="color-item">
                    <span>Primary Text</span>
                    <span style="margin-left: auto">{{
                      company_form.dialog_header_text_color
                    }}</span>
                    <el-color-picker
                      v-model="company_form.dialog_header_text_color"
                      size="small"
                    ></el-color-picker>
                  </div>
                  <!-- <div class="color-item">
                    <span>Background</span>
                    <span style="margin-left: auto">{{
                      company_form.bodyColor
                    }}</span>
                    <el-color-picker
                      v-model="company_form.theme.bodyColor "
                      size="small"
                    ></el-color-picker>
                  </div> -->
                  <div class="color-item">
                    Default Style
                    <div class="mr-05" style="padding-left: 5px">
                      <i
                        v-if="!isColorFilled"
                        class="el-icon-turn-off"
                        @click="toggleColorFilled"
                      ></i>
                      <i
                        v-else
                        class="el-icon-open"
                        style="color: blue"
                        @click="toggleColorFilled"
                      ></i>
                    </div>
                    Color Filled
                  </div>
                  <div v-if="isColorFilled">
                    <div class="color-item">
                      <span>Menu</span>
                      <span style="margin-left: auto">{{
                        company_form.sidemenu_background
                      }}</span>
                      <el-color-picker
                        v-model="company_form.sidemenu_background"
                        size="small"
                      ></el-color-picker>
                    </div>
                    <!-- <div class="color-item">
                      <span>Sub Menu</span>
                      <span style="margin-left: auto">{{
                        company_form.side_menu.submenu
                      }}</span>
                      <el-color-picker
                        v-model="company_form.side_menu.submenu"
                        size="small"
                      ></el-color-picker>
                    </div> -->
                    <div class="color-item">
                      <span>Menu Text</span>
                      <span style="margin-left: auto">{{
                        company_form.sidemenu_text
                      }}</span>
                      <el-color-picker
                        v-model="company_form.sidemenu_text"
                        size="small"
                      ></el-color-picker>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Button & Font" name="3" class="items">
                <div class="ml-1 mt-10">
                  <!-- `<div class="d-flex-col">
                    <span class="color-item">Choose Your Font</span>
                    <el-select style="width: 70%"></el-select>
                  </div>` -->
                  <div class="d-flex-col">
                    <span class="color-item">Button Type</span>
                    <el-select
                      v-model="selectedButtonType"
                      @change="changeButtonType"
                      style="width: 70%"
                    >
                      <el-option
                        label="Rectangular"
                        value="rectangular"
                      ></el-option>
                      <el-option label="Rounded" value="rounded"></el-option>
                    </el-select>
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Menu Settings" name="4" class="items">
                <div class="ml-1 mt-10">
                  <span class="colors-ProfileSettings"
                    >Menu Displaying Type</span
                  >
                  <el-radio-group
                    v-model="company_form.menuType"
                    class="radios"
                  >
                    <el-row>
                      <el-radio label="Vertical"></el-radio>
                      <el-radio label="Horizontal"></el-radio>
                    </el-row>
                  </el-radio-group>
                  <br />
                  <span class="colors-ProfileSettings">Display Menu Icon </span>
                  <el-radio-group
                    v-model="company_form.menuIcon"
                    class="radios"
                  >
                    <el-row>
                      <el-radio :label="true">Yes</el-radio>
                      <el-radio :label="false">NO</el-radio>
                    </el-row>
                  </el-radio-group>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="check-section">
            <div
              :style="{ width: secondContainerWidth }"
              class="second-container"
            >
              <div
                class="input-buttons-container d-flex"
                :style="{ backgroundColor: company_form.menubackground }"
              >
                <div class="image-container">
                  <img
                    v-if="this.imgData"
                    :src="this.imgData"
                    height="45"
                    width="155"
                    class="avatar"
                  />

                  <img
                    v-else
                    :src="logo"
                    height="45"
                    width="135"
                    class="avatar"
                  />
                </div>
                <div style="margin-left: auto">
                  <el-input
                    v-model="company_form.name"
                    disabled
                    style="padding-top: 3px"
                  ></el-input>
                </div>
                <div class="login-signup ml-1">
                  <el-button
                    :class="[selectedButtonType]"
                    :style="{
                      backgroundColor: company_form.dialog_header_color,
                    }"
                    ><span
                      :style="{ color: company_form.dialog_header_text_color }"
                      >Login</span
                    ></el-button
                  >
                  <el-button
                    :class="[selectedButtonType]"
                    :style="{
                      backgroundColor: company_form.dialog_header_color,
                    }"
                    ><span
                      :style="{ color: company_form.dialog_header_text_color }"
                      >Signup</span
                    ></el-button
                  >
                </div>
              </div>
              <div class="underline"></div>
              <div :class="[layoutClass]">
                <div class="containerr">
                  <div
                    class="sidebar-in-preview"
                    :style="{
                      backgroundColor: company_form.sidemenu_background,
                    }"
                  >
                    <ul
                      class="scrollable-list-icon"
                      :style="{ color: company_form.sidemenu_text }"
                    >
                      <li
                        v-for="(item, i) in Object.keys(
                          excelFileHeadersApplication
                        )"
                        :key="excelFileHeadersApplication[item].table_name"
                      >
                        <icons
                          v-if="company_form.menuIcon && i < 10"
                          :iconName="
                            excelFileHeadersApplication[item].entity_symbol
                          "
                          :style="{
                            '--primary-color': company_form.menubackground,
                            fill: company_form.menubackground,
                          }"
                        ></icons>
                        <el-tooltip :content="item" v-if="i < 10">
                          <span
                            v-if="i < 10"
                            :style="{ color: company_form.sidemenu_text }"
                          >
                            {{ item | truncate(15, "...") }}
                          </span>
                        </el-tooltip>
                      </li>
                    </ul>
                    <!-- <div style="margin-left:20px">
                    <div class="d-flex">
                    <icons v-if="company_form.menuIcon" :iconName="'File'"></icons>
                      <span :style="{ color: company_form.side_menu.submenu }">Files</span>
                  </div>
                  </div> -->
                  </div>
                  <div class="check-container" style="width: 100%">
                    <div>
                      <div class="table-in-preview">
                        <div
                          class="header"
                          :style="{
                            backgroundColor: company_form.dialog_header_color,
                          }"
                        >
                          <span
                            :style="{
                              color: company_form.dialog_header_text_color,
                            }"
                            >Name</span
                          >
                          <span
                            class="status"
                            :style="{
                              color: company_form.dialog_header_text_color,
                            }"
                            >Status</span
                          >
                        </div>
                        <div class="underline"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin: auto" v-else>
          This configuration is not needed while creating application in same
          workspace
        </div>
      </section>
      <section v-if="this.active === 3">
        <el-tabs v-model="activeNameForApplicationImport">
          <el-tab-pane
            v-for="tab in Object.keys(jsonDataOfExcel)"
            :label="tab"
            :key="tab"
            :name="tab"
          >
            <SuccessAndErrorsTable
              :templatesData="previewData[tab].templatesData"
              :currentEntity="previewData[tab].entity"
              :foundArray="previewData[tab].fields"
              :successedExcelImportData="previewData[tab].successedData"
            >
            </SuccessAndErrorsTable>
          </el-tab-pane>
        </el-tabs>
      </section>
      <hr />
    </div>
    <span class="fixed-footer">
      <footer>
        <div
          class="footer"
          v-if="this.active === 0 && creationType == 'Upload File'"
        >
          <div style="text-align: right">
            <el-button @click="goBack">Cancel</el-button>
            <el-button
              type="danger"
              @click="gotoMappingScreen"
              :disabled="buttonOneDisabled"
              >Next</el-button
            >
          </div>
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-if="
            this.active === 0 &&
            creationType == 'Ask AI' &&
            responseCaptured &&
            !cardLoading
          "
        >
          <div class="all-responses">
            <el-tooltip content="Previous" placement="top">
              <i
                class="el-icon-arrow-left"
                @click="handleArrowClick('left')"
                style="cursor: pointer"
              ></i>
            </el-tooltip>
            <span v-if="currentResponseIndex.toString()"
              >{{ currentResponseIndex + 1 }} / {{ responseArray.length }}</span
            >
            <el-tooltip content="Next" placement="top">
              <i
                class="el-icon-arrow-right"
                style="cursor: pointer"
                @click="handleArrowClick('right')"
              ></i>
            </el-tooltip>
          </div>
          <el-input
            type="textarea"
            :disabled="checkNextDisabled(true)"
            :placeholder="getPlaceHolder()"
            v-model="changes_prompt"
            class="input-with-select ml-1"
          >
          </el-input>
          <img
            :src="require('@/assets/Enter.svg')"
            @click="generateResponse(true)"
            :class="[
              isIconEnabled
                ? 'generate-icon-enabled'
                : 'generate-icon-disabled',
            ]"
          />
          <span class="ml-1 mr-1 Or-In-Footer">or</span>
          <el-button
            class="btn btn-sm btn-outline-primary Regenrate-Footer"
            type="button"
            @click="generateResponse()"
            :disabled="checkNextDisabled()"
            >Regenerate <i class="el-icon-refresh"></i
          ></el-button>
          <el-button
            type="danger"
            @click="gotoMappingScreen"
            :disabled="checkNextDisabled()"
            >Next</el-button
          >
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-else-if="this.active === 1"
        >
          <el-button @click="goBack">Cancel</el-button>
          <el-button
            type="danger"
            @click="getMappingScreenData"
            :disabled="buttonTwoDisabled"
            >Next</el-button
          >
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-else-if="this.active === 2"
        >
          <el-button @click="goBack">Cancel</el-button>
          <el-button
            v-if="showCreateButtonInStep2"
            type="danger"
            @click="importEntitiesData"
            :disabled="buttonThreeDisabled"
            >Create</el-button
          >
          <el-button
            v-else
            type="danger"
            @click="buttonThree"
            :disabled="buttonThreeDisabled"
            >Next</el-button
          >
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-else-if="this.active === 3"
        >
          <el-button @click="goBack">Cancel</el-button>
          <el-button
            type="danger"
            @click="importEntitiesData"
            :disabled="buttonFourDisabled"
            >Create</el-button
          >
        </div>
      </footer>
    </span>
    <dialog-component
      :title="'Add New Group'"
      :visible="showAddGroupDialog"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="showAddGroupDialog = false"
      :isShowFooter="false"
    >
      <div v-loading="loadingSaveGroup" class="mt-10 mb-10">
        <span>
          <p>Group Title</p>
          <el-input
            type="text"
            v-model="groupName"
            placeholder="Enter Title"
          ></el-input>
        </span>
        <div class="mt-15">
          <span slot="footer" class="dialog-footer mt-10">
            <el-button @click="showAddGroupDialog = false">Cancel</el-button>
            <el-button
              type="success"
              @click="saveGroup"
              :disabled="!groupName || !groupName.trim()"
              class="px-20"
            >
              <i class="el-icon-check"></i> Save Group
            </el-button>
          </span>
        </div>
      </div>
    </dialog-component>
    <dialog-component
      title="'Create form template'"
      :visible="centerDialogVisible"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="centerDialogVisible = false"
      :isShowFooter="false"
    >
      <div class="mt-10 mb-10">
        <span>
          <p for="tempTitle">Template Title</p>
          <el-input
            placeholder="Enter template name"
            v-model="formTemplateName"
            clearable
            id="tempTitle"
          >
          </el-input>
        </span>
        <div class="mt-15">
          <span slot="footer" class="dialog-footer mt-10">
            <el-button @click="centerDialogVisible = false">Cancel</el-button>
            <el-button
              type="primary"
              @click="addTemplate"
              :disabled="formTemplateName.trim().length === 0"
              >Save</el-button
            >
          </span>
        </div>
      </div>
    </dialog-component>
    <el-dialog
      :visible.sync="importDialogueVisible"
      :width="getIsMobile ? '100%' : '35%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @before-close="handleCloseDialog"
    >
      <div class="text-center">
        <img
          src="@/assets/img/importDataLoading.gif"
          style="height: 30vh; width: 15vw"
        />
        <p>Please wait</p>
        <h1>Your application creation is in progress...</h1>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="mappingStatusDialogVisible"
      :width="getIsMobile ? '100%' : '40%'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @before-close="handleCloseDialog"
    >
      <div class="text-center">
        <img
          src="@/assets/img/mappingDone.svg"
          style="height: 30vh; width: 15vw"
        />
        <h1>Your application created successfully...</h1>
        <el-button type="primary" @click="gotoDashboard"> Done </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="mappingStatusDialogVisibleNewCompany"
      :width="getIsMobile ? '100%' : '40%'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="text-center">
        <img
          src="@/assets/img/mappingDone.svg"
          style="height: 30vh; width: 15vw"
        />
        <h1>Your application created successfully in new workspace...</h1>
        <p>Do you want to switch to the new workspace ?</p>
        <el-button @click="gotoDashboard" style="width: 100px">No</el-button>
        <el-button type="primary" @click="goToNewWorkspace" style="width: 100px"
          >Yes</el-button
        >
      </div>
    </el-dialog>
    <dialog-component
      :title="entityVariableField.excel_field"
      :visible="entityVarDialogVisible"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="entityVarDialogVisible = false"
    >
      <div class="entity-variable-container mt-10 mb-10">
        <div>
          <label for="valueOfEntity">Entities</label><br />
          <el-select
            v-model="entityVariableField['relationship_key']"
            placeholder="Select ENTITY"
            id="valueOfEntity"
            style="width: 90%"
            @change="getSelectedEntityFieldValue"
            clearable
          >
            <el-option
              v-for="(entityField, index) in currentTemplateEntityFields"
              :key="entityField.excel_field + index"
              :label="entityField.excel_field"
              :value="entityField.entity_id + '#' + entityField.excel_field"
            >
              <span
                >{{ entityField.excel_field
                }}<span style="color: #8492a4; font-weight: 700">
                  Template variable</span
                ></span
              >
            </el-option>
          </el-select>
        </div>
        <br />
        <div>
          <label for="varibleValueOfEntity">ENTITY Variable</label><br />
          <el-select
            v-model="entityVariableField['entityVariableInfo']"
            placeholder="Select Variable"
            id="varibleValueOfEntity"
            style="width: 90%"
            clearable
          >
            <el-option
              v-for="(field, index) in selectedEntityAllFields"
              :key="getFieldKey(field, index)"
              :label="getLabel(field)"
              :value="getValueForEntityVariable(field)"
            >
            </el-option>
          </el-select>
        </div>
        <br />
        <div>
          <label for="fieldAssignable">Is this field ?</label>
          <el-radio-group
            id="fieldAssignable"
            v-model="entityVariableField['field_assignable']"
          >
            <el-radio label="read_only">Read only</el-radio>
            <el-radio label="editable">Editable</el-radio>
            <el-radio label="update_parent">Update parent</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="entityVarDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="setPropertiesForEntityVariable"
          :disabled="disableEntityVariableButton()"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :title="'Select Menu Icon'"
      :visible="iconPickerdialogVisible"
      @before-close="iconPickerdialogVisible = false"
      :containerWidth="'77%'"
      :isShowFooter="false"
      class="icons-in-application"
    >
      <icons-list @icon-data="setIconData"></icons-list>
    </dialog-component>
    <dialog-component
      :title="'Change Company Logo'"
      :visible="logoDialog"
      :width="getIsMobile ? '100%' : '50%'"
      :containerMaxHeight="'80%'"
      @before-close="logoDialog = false"
    >
      <el-row>
        <el-col>
          <div class="mb-1">
            <vue-anka-cropper
              class="upload-file mt-2"
              :options="{
                aspectRatio: false,
                closeOnSave: true,
                cropArea: 'box',
                croppedHeight: 1000,
                croppedWidth: 1000,
                cropperHeight: false,
                dropareaMessage:
                  ' Drag & Drop  file here or use the button below.',
                frameLineDash: [1, 1000],
                frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                handleFillColor: 'rgba(255, 255, 255, 0.2)',
                handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                handleSize: 5,
                handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                layoutBreakpoint: 40,
                maxCropperHeight: 400,
                maxFileSize: 8000000,
                overlayFill: 'rgba(0, 0, 0, 0.5)',
                previewOnDrag: true,
                previewQuality: 0.65,
                resultQuality: 0.8,
                resultMimeType: 'image/jpeg',
                resultMimeType: 'image/png',
                resultMimeType: 'image/jpg',
                resultMimeType: 'image/gif',
                selectButtonLabel:
                  'Select File Supported : JPG, PNG, JPEG, GIF',

                showPreview: true,
                skin: 'light',
                uploadData: {},
                uploadTo: false,
              }"
              name="logo"
              @cropper-error="fileError"
              @cropper-preview="uploadFile"
              v-loading="logoLoading"
              @cropper-file-selected="uploadFile"
              @cropper-saved="onUpdate"
              accept="image/jpeg,image/png,image/jpg,image/gif"
            >
            </vue-anka-cropper>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onUpdate">Confirm</el-button>
      </span>
    </dialog-component>
  </section>
</template>
<script>
import * as XLSX from "xlsx";
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import SuccessAndErrorsTable from "../entity/SuccessAndErrorsTable.vue";
import authHelper from "@/mixins/authHelper";
import moment from "moment";
import axios from "axios";
import { postAPICall } from "@/helpers/httpHelper";
import loadingComponentVue from "../loadingComponent.vue";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import FieldsFormatHelper from "@/mixins/FieldsFormatHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
// import { clearDB } from "@/repo/appSesttingRepo";
export default {
  data() {
    return {
      active: 0,
      value1: true,
      iconSrc: require("@/assets/Kodefast-Loading-Animation.gif"),
      logoDialog: false,
      isColorFilled: false,
      selectedButtonType: "rectangular",
      isExcelFileuploaded: false,
      showCreateButtonInStep2: false,
      tableLoading: false,
      selectedIcon: "",
      logo: require("@/assets/Kodefast-Logo-White.png"),
      iconPickerdialogVisible: false,
      jsonDataOfExcel: {},
      excelfilecolumnNames: {},
      excelSheetNames: [],
      activeNameForApplicationImport: "",
      groups: [],
      showAddGroupDialog: false,
      groupName: "",
      activeMenuProfile: "",
      loadingSaveGroup: false,
      excelFileHeaders: [],
      formTemplateName: "",
      centerDialogVisible: false,
      session_id: "",
      newFormTempCount: 0,
      accepted_input_types: [
        "PHONE_COUNTRY_CODE",
        "SINGLE_LINE_TEXT",
        "MULTI_LINE_TEXT",
        "NUMBER",
        "SELECT",
        "MULTI_SELECT",
        "YES_OR_NO",
        "FILE",
        "DATE",
        "TIME",
        "DATE_TIME",
        "DATE_RANGE",
        "TIME_RANGE",
        "DATE_TIME_RANGE",
        "RADIO",
        "RADIO_BUTTON_GROUP",
        "SIGNATURE",
        "CHECKBOX",
        "CHECKBOX_GROUP",
        "ACTION_BUTTON",
        "FORMULA",
        "ENTITY_VARIABLE",
        "CURRENCY",
        "RADIO",
        "ENTITY",
        "AUTO_INCREMENT_NUMBER",
        "WEEKDAYS",
        "DATA_TABLE",
        "LIST",
        "CONCATENATE",
        "IMAGE",
      ],
      allFormFIelds: [
        {
          key: "SINGLE_LINE_TEXT",
          name: "Single Line Text",
          img_url: "SingleLine.svg",
        },
        {
          key: "MULTI_LINE_TEXT",
          name: "Multi Line Text",
          img_url: "MultiLine.svg",
        },
        {
          key: "SELECT",
          name: "Select",
          img_url: "Select.svg",
        },
        {
          key: "NUMBER",
          name: "Number",
          img_url: "Number.svg",
        },
        {
          key: "MULTI_SELECT",
          name: "Multiple Select",
          img_url: "MultiSelect.svg",
        },
        {
          key: "DATE",
          name: "Date",
          img_url: "Date.svg",
        },
        {
          key: "TIME",
          name: "Time",
          img_url: "Date.svg",
        },
        {
          key: "DATE_TIME",
          name: "Date Time",
          img_url: "Date.svg",
        },
        {
          key: "YES_OR_NO",
          name: "Yes or No",
          img_url: "YesNo.svg",
        },

        {
          key: "CHECKBOX",
          name: "Checkbox",
          img_url: "CheckBox.svg",
        },
        {
          key: "RADIO",
          name: "Radio",
          img_url: "CheckBox.svg",
        },
        {
          key: "PHONE_COUNTRY_CODE",
          name: "Phone",
          img_url: "Number.svg",
        },
        {
          key: "CURRENCY",
          name: "Currency",
          img_url: "Number.svg",
        },
        {
          key: "IMAGE",
          name: "Image",
          img_url: "File.svg",
        },
        // {
        //     key: "AUTO_INCREMENT_NUMBER",
        //     name: "Auto Increment Number",
        //     img_url: "SingleLine.svg",
        // },
        {
          key: "ENTITY",
          name: "ENTITY",
          img_url: "glodal_variable.svg",
        },
        {
          key: "ENTITY_VARIABLE",
          name: "ENTITY Variable",
          img_url: "glodal_variable.svg",
        },
        {
          key: "AUTO_INCREMENT_NUMBER",
          name: "Auto Increment Number",
          img_url: "MultiSelect.svg",
        },
        {
          key: "FILE",
          name: "File",
          img_url: "File.svg",
        },
        // {
        //   key: "ENTITY_TABLE",
        //   name: "ENTITY Table",
        //   img_url: "MultiLine.svg",
        // },
      ],
      currencyTypes: ["USD", "CAD", "AUD", "INR", "EUR"],
      rowIndex: -1,
      companyEntities: [],
      companyTemplates: [],
      standardTemp: {},
      createdFormTemplate: [],
      previewData: {},
      groupLoading: false,
      importDialogueVisible: false,
      mappingStatusDialogVisible: false,
      mappingStatusDialogVisibleNewCompany: false,
      visitedEntities: {},
      entityVarDialogVisible: false,
      entityVariableField: {},
      selectedEntityAllFields: [],
      selectedEntityType: "",
      currentTemplateEntityFields: [],
      buttonOneDisabled: false,
      buttonTwoDisabled: false,
      buttonThreeDisabled: false,
      buttonFourDisabled: false,
      creationType: "Ask AI",
      application_prompt: "",
      changes_prompt: "",
      cardLoading: false,
      loadingText: "Generating your application... please wait...",
      responseCaptured: false,
      selectValue: "",
      excelFileHeadersApplication: {},
      tablesData: [],
      gotErrorResponse: false,
      errorResponseMessage: "",
      allIconNames: [],
      showGroups: true,
      showEntityTypes: true,
      company_form: {
        name: this.getWorkspaceName(),
        logo: "",
        about_company_text: "",
        dialog_header_color: "#3A065C",
        dialog_header_text_color: "#FFFFFF",
        sidemenu_background: "#FFFFFF",
        sidemenu_text: "#000000",
        menubackground: "#E7DFDF",
        menuType: "Vertical",
        menuIcon: true,
      },
      imgData: "",
      logoLoading: false,
      responseArray: [],
      currentResponseIndex: 0,
    };
  },
  mixins: [
    PermissionsHelper,
    NavigationHelper,
    TemplateBuilderHelper,
    authHelper,
    FieldsFormatHelper,
    EntityHelper,
  ],
  methods: {
    getLabel(field) {
      if (this.selectedEntityType == "old") return field.label;
      let label = field.template_id.includes("newTemplate")
        ? field.template_id.split("#")[1]
        : "Contact Details";
      return `${label} -> ${field.excel_field}`;
    },
    getFieldKey(field, index) {
      return field?.excel_field ? field.excel_field + index : field.name;
    },
    openLogoDialog() {
      this.logoDialog = true;
    },
    changeButtonType(value) {
      this.selectedButtonType = value;
    },
    getValueForEntityVariable(field) {
      if (this.selectedEntityType == "old")
        return `${field.template_key}#${field.inputType}`;
      let key = field.excel_field
        .trim()
        .toLowerCase()
        .replace(/[ /&]/g, "_")
        .replace(/[^\w-]+/g, "")
        .replace(/(-)\1+/gi, (str, match) => {
          return match[0];
        });
      return `${field.template_id}#${key}#${field.inputType}`;
    },
    async uploadExcelfile(file) {
      this.excelFile = file;
      let fileNameArray = this.excelFile.name.split(".");
      fileNameArray.splice(fileNameArray.length - 1, 1);
      this.excelFile.name = fileNameArray.join(".");
      this.isExcelFileuploaded = true;
      this.handleFileUpload(this.excelFile);
    },
    toggleColorFilled() {
      this.isColorFilled = !this.isColorFilled;
    },
    buttonThree() {
      this.buttonThreeDisabled = true;
      this.active = 3;
      this.buttonThreeDisabled = false;
    },
    setIconData(name) {
      this.excelFileHeadersApplication[this.activeNameForApplicationImport][
        "entity_symbol"
      ] = name;
      this.iconPickerdialogVisible = false;
    },
    removeExcelFile() {
      this.jsonDataOfExcel = {};
      this.isExcelFileuploaded = false;
      this.excelFile = null;
    },
    async handleFileUpload(fileData) {
      const file = fileData.raw;
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = Buffer.from(e.target.result);
        const workbook = XLSX.read(data, { type: "array", cellDates: true });
        this.excelSheetNames = workbook.SheetNames;
        for (const sheetName of workbook.SheetNames) {
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const convertedJsonData = await this.convertDataToJSON(jsonData);
          let presentSheetName = sheetName;
          let obj = {};
          obj[presentSheetName] = convertedJsonData;
          let columnNamesObj = {};
          columnNamesObj[presentSheetName] = jsonData[0];
          this.excelfilecolumnNames = {
            ...this.excelfilecolumnNames,
            ...columnNamesObj,
          };
          this.jsonDataOfExcel = { ...this.jsonDataOfExcel, ...obj };
        }
        this.activeNameForApplicationImport = this.excelSheetNames[0];
      };
      reader.onerror = (e) => {
        console.error("File reading error:", e);
      };
      reader.readAsArrayBuffer(file);
    },
    async convertDataToJSON(data) {
      const headers = data[0];
      //this.excelfilecolumnNames = [...data[0]];
      const jsonData = [];
      for (let i = 1; i < data.length; i++) {
        const row = data[i];
        const item = {};
        let allKeys = [];
        for (let j = 0; j < headers.length; j++) {
          if (headers[j] && headers[j].includes(".")) {
            headers[j] = headers[j].split(".")[1];
          }
          if (headers[j] && headers[j] !== undefined) {
            const value = row[j] !== null ? row[j] : "";
            let itemKeys = Object.keys(item);
            allKeys.push(headers[j]);
            if (
              itemKeys &&
              itemKeys.length > 0 &&
              itemKeys.includes(headers[j])
            ) {
              let count =
                allKeys.filter((element) => element === headers[j]).length - 1;
              headers[j] = headers[j] + "_" + count;
              item[headers[j]] = value;
            } else {
              item[headers[j]] = value;
            }
          }
        }
        jsonData.push(item);
      }
      return jsonData;
    },
    async generateResponse(isChanges = false) {
      try {
        if (!isChanges) {
          this.responseArray = [];
        }
        let prompt = isChanges ? this.changes_prompt : this.application_prompt;
        this.loadingText = `${
          isChanges ? "Updating" : "Generating"
        } your application.It will take upto 1-2 minutes.please wait...`;
        this.cardLoading = true;
        this.gotErrorResponse = false;
        this.excelSheetNames = [];
        let params = {
          application_name: prompt,
          session_id: this.session_id,
          flag: !isChanges,
        };
        let response = await axios.post(
          "https://ai.esigns.io/generate_tables/",
          params
        );
        if (response?.data) {
          this.$message.success("Application generated successfully");
          this.tablesData = response.data?.tables;
          // this.updateFileHeaders();
          let permission = true;
          this.tablesData.map((e) => {
            if (
              !(e.attributes?.length && e.table_name && e.entity_type && e.icon)
            ) {
              permission = false;
            }
          });
          if (!permission) {
            this.gotErrorResponse = true;
            this.errorResponseMessage =
              "Some entities are not fully generated. Please use minimal tables.";
            this.cardLoading = false;
            return this.$message.warning(
              "Some entities are not fully generated. Please use minimal tables."
            );
          }
          this.responseCaptured = true;
          this.changes_prompt = "";
          this.excelSheetNames = this.tablesData.map((table) => {
            return table.table_name;
          });
          this.responseArray.push(this.tablesData);
          this.currentResponseIndex = this.responseArray.length - 1;
        }
        this.cardLoading = false;
      } catch (err) {
        this.$message.warning(
          "We encountered an issue with the response text, and additional efforts are required to create the application at this time."
        );
        this.gotErrorResponse = true;
        this.errorResponseMessage =
          "We encountered an issue with the response text, and additional efforts are required to create the application at this time.";
        this.cardLoading = false;
        console.log("generateResponse", err);
      }
    },
    getCurrentRowFields(row) {
      if (row?.attributes?.length) {
        return row.attributes.map((e) => {
          return {
            name: e.name,
            required: e.required,
            type: e.type,
          };
        });
      }
      return [];
    },
    async goBack() {
      this.$confirm(
        "Are you sure to go back? All you data will be lost.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.$router.push({ path: "/apps" });
      });
    },
    async gotoMappingScreen() {
      this.buttonOneDisabled = true;
      this.tableLoading = true;
      let permission = true;
      if (this.creationType == "Upload File" && !this.isExcelFileuploaded) {
        this.$notify.error({
          title: "Error",
          message: "Please upload a file",
        });
        permission = false;
        return;
      } else if (this.creationType == "Ask AI") {
        if (!this.tablesData?.length) {
          permission = false;
          return this.$notify.error({
            title: "Error",
            message: "No tables detected",
          });
        }
        this.excelSheetNames = this.tablesData.map((table) => {
          return table.table_name;
        });
      }

      if (this.excelSheetNames && this.excelSheetNames.length) {
        let emptySheetNameExists = this.excelSheetNames.find(
          (e) => e.trim() == ""
        );
        if (emptySheetNameExists !== undefined) {
          this.$notify.error({
            title: "Error",
            message: "Please make sure that none of the sheet names are empty.",
          });
          permission = false;
          return;
        }
      }
      if (!this.$route.query.workspace) {
        let companyEntityNames = this.companyEntities.map((entity) => {
          return entity.name;
        });
        let index = 0;
        do {
          const name = this.excelSheetNames[index];
          if (companyEntityNames.includes(name)) {
            this.$notify.error({
              title: "Error",
              message: `Entity is already existed with name ${name}`,
            });
            permission = false;
            break;
          }
          index++;
        } while (index < this.excelSheetNames.length);
        // this.companyTemplates = await this.fetchAllTemplates();
      }
      if (permission) {
        this.tableLoading = true;
        [this.companyTemplates, this.allIconNames] = await Promise.all([
          this.fetchAllTemplates(),
          this.fetchIconNames(),
        ]);
        // this.allIconNames = await this.fetchIconNames();
        if (this.creationType == "Upload File") {
          let fileName = this.excelFile.name;
          var formData = new FormData();
          formData.append(
            "file",
            this.excelFile.raw !== undefined
              ? this.excelFile.raw
              : this.excelFile
          );
          let params = {
            data: formData,
            fileName: fileName ? fileName : "",
          };
          await this.$store.dispatch("entities/storeUploadedFile", params);
          if (this.getstoreUploadedFileStatus) {
            let params = {
              fileName: this.getstoreUploadedFileStatus,
              excelHeaders: this.excelfilecolumnNames,
            };
            let response = await postAPICall(
              "POST",
              "/get-excel-headers-app",
              params
            );
            this.excelFileHeadersApplication = response.data;
          }
        } else {
          this.updateFileHeaders();
        }
        this.active = this.active + 1;
        if (this.$route.query.workspace == undefined) {
          await this.$store.dispatch("entityGroups/fetEntityGroupsData", {
            get_all: true,
          });
          if (this.getAllEntityGroupsData && this.getAllEntityGroupsData.data) {
            this.groups = this.getAllEntityGroupsData.data;
          }
          this.companyEntities = this.companyEntities.filter(
            (e) => e.primaryFields?.length
          );
          Object.keys(this.excelFileHeadersApplication).forEach((e) => {
            this.companyEntities.forEach((entity) => {
              this.excelFileHeadersApplication[e]["available_entities"].push({
                name: entity.name,
                value: `${entity._id}#old`,
                primaryFields: entity.primaryFields[0],
              });
            });
          });
        } else {
          this.groups.push({
            _id: `new#${this.$route.query.workspace}`,
            name: this.$route.query.workspace,
          });
        }
        this.groupLoading = true;
        if (this.groups && this.groups.length) {
          Object.keys(this.excelFileHeadersApplication).forEach((e) => {
            this.excelFileHeadersApplication[e]["groups"].push(
              this.groups[0]._id
            );
          });
        }
        this.groupLoading = false;
        Object.keys(this.excelFileHeadersApplication).forEach((entity) => {
          this.visitedEntities[entity] = false;
        });
        for (let i = 0; i < this.excelSheetNames.length; i++) {
          this.activeNameForApplicationImport = this.excelSheetNames[i];
          this.entityFieldMapping();
        }
        this.activeNameForApplicationImport = this.excelSheetNames[0];
        this.tableLoading = false;
      } else {
        this.tableLoading = false;
        return;
      }
      this.buttonOneDisabled = false;
    },
    async getMappingScreenData() {
      if (this.creationType == "Ask AI") {
        this.activeNameForApplicationImport = this.excelSheetNames[0];
      }
      this.buttonTwoDisabled = true;
      let eachEntityIterator = 0;
      let errorMessagesCount = 0;
      do {
        const eachEntity = Object.keys(this.excelFileHeadersApplication)[
          eachEntityIterator
        ];
        let primaryFieldsCount = 0;
        let emptyFieldCount = 0;
        let entityVariableNotConfiguredCount = 0;

        if (
          this.excelFileHeadersApplication[eachEntity] &&
          this.excelFileHeadersApplication[eachEntity]["columnHeaders"]
        ) {
          this.excelFileHeadersApplication[eachEntity]["columnHeaders"].forEach(
            (field) => {
              if (field && field.isPrimaryField) {
                primaryFieldsCount++;
              }
              if (field.template_id?.toString() === "") {
                emptyFieldCount++;
              }
              if (
                field.inputType == "ENTITY_VARIABLE" &&
                (field.entityVariableInfo == "" || field.relationship_key == "")
              ) {
                entityVariableNotConfiguredCount++;
              }
            }
          );
        }
        if (
          this.excelFileHeadersApplication[eachEntity]["entity_type"] === ""
        ) {
          this.$notify.error({
            title: "Error",
            message: `Entity type is required for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        } else if (
          this.excelFileHeadersApplication[eachEntity]["groups"] &&
          this.excelFileHeadersApplication[eachEntity]["groups"].length === 0
        ) {
          this.$notify.error({
            title: "Error",
            message: `Atleast one group is required for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        } else if (
          this.excelFileHeadersApplication[eachEntity] &&
          this.excelFileHeadersApplication[eachEntity]["columnHeaders"] &&
          emptyFieldCount ===
            this.excelFileHeadersApplication[eachEntity]["columnHeaders"].length
        ) {
          this.$notify.error({
            title: "Error",
            message: `Atleast select one field for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        } else if (
          this.excelFileHeadersApplication[eachEntity]["entity_type"] ==
          "INDIVIDUAL"
        ) {
          let allColumnNames = [];
          this.excelFileHeadersApplication[eachEntity]["columnHeaders"].forEach(
            (column) => {
              if (
                column.template_id.toString() ==
                this.standardTemp._id.toString()
              ) {
                allColumnNames.push(column.template_filed_id);
              }
            }
          );
          if (
            !allColumnNames.includes("first_name") &&
            !allColumnNames.includes("last_name") &&
            !allColumnNames.includes("name")
          ) {
            this.$notify.error({
              title: "Error",
              message: "First name and Last name or Name is required",
            });
            errorMessagesCount++;
            break;
          } else if (!allColumnNames.includes("email")) {
            this.$notify.error({
              title: "Error",
              message: "Email is required",
            });
            errorMessagesCount++;
            break;
          }
        } else if (primaryFieldsCount === 0) {
          this.$notify.error({
            title: "Error",
            message: `Atleast one primary field is required for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        } else if (entityVariableNotConfiguredCount !== 0) {
          this.$notify.error({
            title: "Error",
            message: `Please configure all the Entity Variable fields for ${eachEntity}`,
          });
          errorMessagesCount++;
          break;
        }
        eachEntityIterator++;
      } while (
        eachEntityIterator <
        Object.keys(this.excelFileHeadersApplication).length
      );
      if (errorMessagesCount > 0) {
        return;
      } else {
        this.tableLoading = true;
        this.activeNameForApplicationImport = this.excelSheetNames[0];
        await this.makePreviewData();
        this.active++;
        this.tableLoading = false;
        this.$notify.success({
          title: "Success",
          message: "Succesfully configured Entities and Templates",
        });
      }
      this.buttonTwoDisabled = false;
      if (this.$route.query.workspace == undefined) {
        this.active += 1;
      }
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    uploadFile(file) {
      this.logoLoading = true;
      this.imgData = file;

      // Use Vue's nextTick to wait for the DOM update
      this.$nextTick(() => {
        const doc = document.querySelector(".ankaCropper__previewArea");
        if (doc) {
          const img = doc.querySelector("img");
          if (img) {
            this.imgData = img.src;
          } else {
            console.error(
              "Image element not found inside .ankaCropper__previewArea."
            );
          }
        } else {
          console.error(".ankaCropper__previewArea element not found.");
        }
      });

      this.buttondisale = false;
      this.logoLoading = false;
    },

    async makePreviewData() {
      Object.keys(this.excelFileHeadersApplication).forEach((entity) => {
        if (!this.jsonDataOfExcel[entity]) {
          return;
        } else if (Object.keys(this.jsonDataOfExcel[entity]).length === 0) {
          this.showCreateButtonInStep2 = true;
        }
        let templates = [];
        this.excelFileHeadersApplication[entity]["formTemplates"].forEach(
          (e) => {
            if (!e._id.includes("newTemplate")) {
              templates.push({
                template_id: {
                  type: "STANDARD",
                },
                _id: e._id,
              });
            }
          }
        );
        let tempData = [];
        this.jsonDataOfExcel[entity].forEach((sheetData) => {
          if (
            this.excelFileHeadersApplication[entity]["entity_type"] ==
            "INDIVIDUAL"
          ) {
            Object.keys(sheetData).forEach((e) => {
              let field = this.excelFileHeadersApplication[entity][
                "columnHeaders"
              ].find((f) => f.excel_field.toString() == e.toString());
              if (!field.template_id?.toString().includes("#")) {
                switch (field.template_filed_id) {
                  case "name":
                    sheetData["Name"] = sheetData[e.toString()];
                    break;
                  case "first_name":
                    sheetData["First Name"] = sheetData[e.toString()];
                    break;
                  case "last_name":
                    sheetData["Last Name"] = sheetData[e.toString()];
                    break;
                  case "email":
                    sheetData["Email"] = sheetData[e.toString()];
                    break;
                }
              }
            });
          }
        });
        let primaryFields = [];
        this.excelFileHeadersApplication[entity]["columnHeaders"].forEach(
          (e) => {
            if (e.template_id.toString().includes("#")) {
              e.template_filed_id = e.excel_field
                .trim()
                .toLowerCase()
                .replace(/[ /&]/g, "_")
                .replace(/[^\w-]+/g, "")
                .replace(/(-)\1+/gi, (str, match) => {
                  return match[0];
                });
              e.key = e.template_filed_id;
            } else {
              switch (e.template_filed_id) {
                case "name":
                  e.excel_field = "Name";
                  break;
                case "first_name":
                  e.excel_field = "First Name";
                  break;
                case "last_name":
                  e.excel_field = "Last Name";
                  break;
                case "email":
                  e.excel_field = "Email";
                  break;
              }
              e.key = e.template_filed_id;
            }
            if (e.isPrimaryField) {
              if (e && e.isPrimaryField) {
                primaryFields.push(e);
              }
            }
            if (e.inputType == "SELECT") {
              let selectedOptions = this.jsonDataOfExcel[entity].map((f) => {
                if (f[e.excel_field] !== undefined) {
                  return f[e.excel_field].toString();
                }
              });
              let uniqueOptions = selectedOptions.filter(
                (value, index, self) => {
                  return (
                    value !== undefined &&
                    value.toString() != "" &&
                    self.indexOf(value) === index
                  );
                }
              );
              e.options = [...e.options, ...uniqueOptions];
            } else if (e.inputType == "MULTI_SELECT") {
              this.jsonDataOfExcel[entity].forEach((f) => {
                if (f[e.excel_field] !== undefined) {
                  if (typeof f[e.excel_field] !== "string") {
                    f[e.excel_field] = f[e.excel_field].toString();
                  }
                  if (f[e.excel_field].includes(",")) {
                    let arr = f[e.excel_field].split(",");
                    arr.forEach((op) => {
                      op = op.trim();
                      if (op && !e.options.includes(op)) {
                        e.options.push(op);
                      }
                    });
                  } else {
                    if (
                      f[e.excel_field] &&
                      !e.options.includes(f[e.excel_field])
                    ) {
                      f[e.excel_field] = f[e.excel_field].trim();
                      e.options.push(f[e.excel_field]);
                    }
                    this.jsonDataOfExcel[entity][
                      this.jsonDataOfExcel[entity].indexOf(f)
                    ][e.excel_field] = [f[e.excel_field]];
                  }
                }
              });
            }
          }
        );
        let dummyEntity = {
          entity_type: this.excelFileHeadersApplication[entity]["entity_type"],
          templates: templates,
          primaryFields: primaryFields,
        };
        this.jsonDataOfExcel[entity].forEach((sheetData) => {
          let rowObj = {};
          Object.keys(sheetData).map((e) => {
            this.excelFileHeadersApplication[entity]["columnHeaders"].map(
              (f) => {
                if (f.excel_field.toString() == e.toString()) {
                  let obj = {};
                  if (
                    f.inputType == "MULTI_SELECT" &&
                    sheetData[e] !== "" &&
                    sheetData[e] !== undefined
                  ) {
                    sheetData[e] = sheetData[e].toString();
                    if (sheetData[e].includes(",")) {
                      sheetData[e] = sheetData[e].split(",");
                    } else {
                      sheetData[e] = [sheetData[e]];
                    }
                  } else if (f.inputType == "DATE") {
                    sheetData[e] = moment(sheetData[e]).add(10, "seconds");
                  }
                  // else if (f.inputType == "CURRENCY") {
                  //     if (typeof sheetData[e] !== "number") {
                  //         sheetData[e] = "";
                  //     }
                  // }
                  obj[f.template_filed_id] = sheetData[e];
                  rowObj[f.template_id] = {
                    ...rowObj[f.template_id],
                    ...obj,
                  };
                }
              }
            );
          });
          tempData.push(rowObj);
        });
        this.previewData[entity] = {
          entity: dummyEntity,
          templatesData: tempData,
          fields: this.excelFileHeadersApplication[entity]["columnHeaders"],
          successedData: [],
        };
      });
    },
    updateFileHeaders() {
      this.excelFileHeadersApplication = {};
      this.jsonDataOfExcel = {};
      this.tablesData.map((table, i) => {
        this.excelFileHeadersApplication[table.table_name] =
          this.getTableData(i);
        // this.jsonDataOfExcel[table.table_name] = [];
      });
      this.activeNameForApplicationImport = this.tablesData[0].table_name;
    },
    getTableData(index) {
      let data = this.tablesData[index];
      let preparedData = {
        columnHeaders: [],
        entity_type: data.type ? data.type : "GENERAL",
        groups: [],
        entity_symbol: this.getIcon(data.icon),
        available_entities: [],
        formTemplates: [
          {
            _id: `newTemplate#${data.table_name}#${data.table_name}`,
            name: data.table_name,
          },
        ],
      };
      let sampleData = [];
      if (data.sub_table?.length) {
        data.sub_table.forEach((e) => {
          data.attributes.push({
            name: e + "s",
            type: "ENTITY_TABLE",
            required: false,
            entity_table_parent: e,
          });
        });
      }
      data.attributes.map((field, i) => {
        preparedData.columnHeaders.push({
          excel_field: field.name,
          template_id: `newTemplate#${data.table_name}#${data.table_name}`,
          template_filed_id: "",
          inputType: this.accepted_input_types.includes(field.type)
            ? field.type
            : "SINGLE_LINE_TEXT",
          entity_id: "",
          isPrimaryField: i == 0 ? true : false,
          // fieldRepeatedCount: count,
          currency_type: field.currency_type ? field.currency_type : "USD",
          options: field.options ? field.options : [],
          label: field.name,
          key: "",
          entityVariableInfo: "",
          relationship_key: "",
          field_assignable: "read_only",
          auto_increment_prefix: "",
          auto_increment_starting_value: "001",
          is_required: field.required ? field.required : false,
          data_table_columns: [],
          entity_table_parent: field.entity_table_parent || "",
        });
        if (field.sample_data?.length) {
          if (!sampleData.length) {
            field.sample_data.map((data) => {
              sampleData.push({
                [field.name]: data,
              });
            });
          } else {
            field.sample_data.map((data, i) => {
              if (sampleData[i]) sampleData[i][field.name] = data;
            });
          }
          // sampleData[field.name] = field.sample_data;
        }
      });
      for (let j = 0; j < index; j++) {
        preparedData.available_entities.push({
          name: this.tablesData[j].table_name,
          value: `${this.tablesData[j].table_name}#new`,
        });
      }
      this.jsonDataOfExcel[data.table_name] = sampleData.length
        ? sampleData
        : [];
      return preparedData;
    },
    getIcon(iconName) {
      return this.allIconNames.includes(iconName)
        ? iconName
        : this.allIconNames[
            Math.floor(Math.random() * this.allIconNames.length)
          ];
    },
    async importEntitiesData() {
      this.buttonFourDisabled = true;
      this.importDialogueVisible = true;
      let companyCheck = await this.checkIfCompanyAlreadyExists();
      if (companyCheck) {
        this.buttonFourDisabled = false;
        this.importDialogueVisible = false;
        return this.$message.error("Company Name Already Exists.....");
      }
      this.company_form.logo = this.imgData ? this.imgData : "";
      let params = {
        // filepath: this.getstoreUploadedFileStatus,
        entitiesAndTemplates: this.excelFileHeadersApplication,
        entitiesAndTemplatesData: this.previewData,
        newWorkspace: false,
        company_form: this.company_form,
      };
      if (this.$route.query.workspace) {
        params["workspaceName"] = this.$route.query.workspace;
        params["newWorkspace"] = true;
        params["groups"] = this.groups;
      }
      await this.$store.dispatch("apps/importApplicationDataForExcel", {
        data: params,
      });
      this.importDialogueVisible = false;
      if (
        this.getAppImportData &&
        this.getAppImportData.data &&
        this.getAppImportData.data.companyId
      ) {
        // this.mappingStatusDialogVisibleNewCompany = true;
        this.goToNewWorkspace();
      } else {
        if (this.getAppImportData == false) {
          this.$notify.error({
            title: "Error",
            message: "Error in creating the application !",
          });
        } else {
          this.mappingStatusDialogVisible = true;
        }
      }
      this.buttonFourDisabled = false;
    },
    async checkIfCompanyAlreadyExists() {
      try {
        let params = { workspace: this.$route.query.workspace?.trim() };
        await this.$store.dispatch("company/fetchCompanyNameStatus", params);
        if (!this.getCompanyExistsStatus?.success) {
          this.$notify.error({
            title: "Error",
            message: this.getCompanyExistsStatus.message,
          });
          return true;
        }
        return false;
      } catch {
        this.$message.error("Error while checking company name");
      }
    },
    async addGroupPopUP() {
      this.showAddGroupDialog = true;
      await this.$store.dispatch("entityGroups/resetErrors");
    },
    updateGroupInAllEntities() {
      (Object.keys(this.excelFileHeadersApplication) || []).map((e) => {
        this.excelFileHeadersApplication[e]["groups"] =
          this.excelFileHeadersApplication[this.activeNameForApplicationImport][
            "groups"
          ];
      });
      this.$message.success("groups updated in all entities");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      if (this.$route.query.workspace == undefined) {
        await this.$store.dispatch("entityGroups/addEntityGroup", {
          name: this.groupName,
          description: "",
          entities: [],
        });
        if (
          this.getSingleEntityGroupData &&
          this.getSingleEntityGroupData.success
        ) {
          this.loadingSaveGroup = false;
          this.groupName = "";
          this.showAddGroupDialog = false;
          this.groups.push(this.getSingleEntityGroupData.data);
          this.excelFileHeadersApplication[this.activeNameForApplicationImport][
            "groups"
          ].push(this.getSingleEntityGroupData.data._id);
        } else if (this.getError && !this.getError.success) {
          this.loadingSaveGroup = false;
          let msg =
            this.getError && this.getError.message
              ? this.getError.message
              : "Something went wrong";
          this.$message.warning(msg);
        }
      } else {
        this.groups.push({
          _id: `new#${this.groupName}`,
          name: this.groupName,
        });
        Object.keys(this.excelFileHeadersApplication).forEach((tab) => {
          this.excelFileHeadersApplication[tab]["groups"].push(
            `new#${this.groupName}`
          );
        });
        this.groupName = "";
        this.loadingSaveGroup = false;
        this.showAddGroupDialog = false;
      }
    },
    entityFieldMapping() {
      let entityFieldOptions =
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "available_entities"
        ];
      let entityPrimaryField = {};
      entityFieldOptions.map((e) => {
        if (e.value.split("#")[1] == "old") {
          entityPrimaryField[e.primaryFields.label] = e.value;
        } else {
          // let isPrimary = this.excelFileHeadersApplication[e.name][
          //   "columnHeaders"
          // ].find((e) => e.isPrimaryField == true);
          entityPrimaryField[e.name] = e.value;
        }
      });
      this.excelFileHeadersApplication[this.activeNameForApplicationImport][
        "columnHeaders"
      ].forEach((field) => {
        if (Object.keys(entityPrimaryField).includes(field.excel_field)) {
          field.inputType = "ENTITY";
          field.entity_id = entityPrimaryField[field.excel_field];
        }
      });
      let allFieldNames = this.excelFileHeadersApplication[
        this.activeNameForApplicationImport
      ]["columnHeaders"].map((field) => {
        return field.excel_field.toLowerCase().replace(/[-_]/g, "");
      });
      if (!this.visitedEntities[this.activeNameForApplicationImport]) {
        let firstNameExists = false,
          lastNameExists = false,
          nameExists = false,
          emailExists = false;
        allFieldNames.forEach((columnName) => {
          if (
            ["first name", "f name", "firstname", "fname"].includes(columnName)
          ) {
            firstNameExists = true;
          } else if (
            [
              "last name",
              "lastname",
              "lname",
              "l name",
              "sname",
              "s name",
              "surname",
              "sur name",
            ].includes(columnName)
          ) {
            lastNameExists = true;
          } else if (columnName.includes("name")) {
            nameExists = true;
          } else if (columnName.includes("mail")) {
            emailExists = true;
          }
        });
        if (
          ((firstNameExists && lastNameExists) || nameExists) &&
          emailExists
        ) {
          this.excelFileHeadersApplication[this.activeNameForApplicationImport][
            "entity_type"
          ] = "INDIVIDUAL";
          this.addStandardTemp("INDIVIDUAL");
        }
      }
      this.visitedEntities[this.activeNameForApplicationImport] = true;
    },
    handleAutoIncrementChange(data) {
      if (["", null, undefined].includes(data.auto_increment_starting_value)) {
        data.auto_increment_starting_value = 1;
      }
    },
    handleArrowClick(direction) {
      if (direction === "left") {
        this.currentResponseIndex = Math.max(0, this.currentResponseIndex - 1);
      } else if (direction === "right") {
        this.currentResponseIndex = Math.min(
          this.responseArray.length - 1,
          this.currentResponseIndex + 1
        );
      }
      this.tablesData = this.responseArray[this.currentResponseIndex];
      this.excelSheetNames = this.tablesData.map((table) => {
        return table.table_name;
      });
    },
    validateSelectedRow(row) {
      if (row.inputType === "ENTITY") {
        row.entity_id = "";
        row.inputType = "";
      }
      row.template_filed_id = "";
    },
    getOtherEntityNames(currentTableName) {
      return this.excelSheetNames.filter((e) => e != currentTableName);
    },
    validateInputFields(row) {
      if (row.inputType == "ENTITY_VARIABLE") {
        this.entityVariableField = null;
        let entityFields = this.excelFileHeadersApplication[
          this.activeNameForApplicationImport
        ]["columnHeaders"].filter((e) => {
          if (
            e &&
            e.inputType == "ENTITY" &&
            e.template_id.includes("newTemplate") &&
            row.template_id == e.template_id
          ) {
            return true;
          } else {
            return false;
          }
        });
        this.currentTemplateEntityFields = entityFields ? entityFields : [];
        this.entityVariableField = row;
        this.entityVarDialogVisible = true;
      }
    },
    async getSelectedEntityFieldValue() {
      this.selectedEntityAllFields = [];
      let selectedEntity;
      if (this.entityVariableField.relationship_key.includes("#new#")) {
        selectedEntity = Object.keys(this.excelFileHeadersApplication).find(
          (e) => {
            if (e == this.entityVariableField.relationship_key.split("#")[0]) {
              return this.excelFileHeadersApplication[e]["columnHeaders"];
            }
          }
        );
        this.selectedEntityType = "new";
        this.selectedEntityAllFields =
          this.excelFileHeadersApplication[selectedEntity]?.["columnHeaders"] ||
          [];
      } else if (this.entityVariableField.relationship_key.includes("#old#")) {
        this.selectedEntityType = "old";
        selectedEntity = this.companyEntities.find(
          (ent) =>
            ent._id == this.entityVariableField.relationship_key.split("#")[0]
        );
        let allEntityFields = await this.fetchEntityDetails(
          selectedEntity._id,
          true,
          true
        );
        this.selectedEntityAllFields = allEntityFields.filter(
          (e) => !["DATA_TABLE", "ENTITY_TABLE"].includes(e.input_type)
        );
      }
    },
    setPropertiesForEntityVariable() {
      this.excelFileHeadersApplication[this.activeNameForApplicationImport][
        "columnHeaders"
      ].map((header) => {
        if (
          header &&
          header.inputType == "ENTITY_VARIABLE" &&
          header.excel_field == this.entityVariableField.excel_field
        ) {
          header.entityVariableInfo =
            this.entityVariableField["entityVariableInfo"];
          header.relationship_key =
            this.entityVariableField["relationship_key"];
        }
      });
      this.entityVarDialogVisible = false;
    },
    async addStandardTemp(type) {
      // if (this.getAllCompanyTemplatesData && this.getAllCompanyTemplatesData.data) {
      //     this.standardTemp = this.getAllCompanyTemplatesData.data.find(template =>
      //         template.type == 'STANDARD' && template.name == 'Contact details'
      //     )
      // }
      this.showEntityTypes = false;
      if (this.companyTemplates && this.companyTemplates.length) {
        this.standardTemp = this.companyTemplates.find(
          (template) =>
            template.type == "STANDARD" && template.name == "Contact details"
        );
      }
      if (type === "INDIVIDUAL") {
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "formTemplates"
        ].push(this.standardTemp);
        let usedStandardTempFields = {
          first_name: false,
          last_name: false,
          name: false,
          email: false,
        };
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "columnHeaders"
        ].forEach((field) => {
          if (
            field &&
            field.excel_field &&
            (field.excel_field.toString().toLowerCase() == "first name" ||
              field.excel_field.toString().toLowerCase() == "f name" ||
              field.excel_field.toString().toLowerCase() == "firstname" ||
              field.excel_field.toString().toLowerCase() == "fname") &&
            usedStandardTempFields["first_name"] == false
          ) {
            field.template_id = this.standardTemp._id;
            field.template_filed_id = "first_name";
            field.inputType = "SINGLE_LINE_TEXT";
            usedStandardTempFields["first_name"] = true;
          } else if (
            field &&
            field.excel_field &&
            (field.excel_field.toString().toLowerCase() == "last name" ||
              field.excel_field.toString().toLowerCase() == " name" ||
              field.excel_field.toString().toLowerCase() == "lastname" ||
              field.excel_field.toString().toLowerCase() == "lname") &&
            usedStandardTempFields["last_name"] == false
          ) {
            field.template_id = this.standardTemp._id;
            field.template_filed_id = "last_name";
            field.inputType = "SINGLE_LINE_TEXT";
            usedStandardTempFields["last_name"] = true;
          } else if (
            field &&
            field.excel_field &&
            (field.excel_field.toString().toLowerCase() == "name" ||
              field.excel_field.toString().toLowerCase() == "full name" ||
              field.excel_field.toString().toLowerCase() == "fullname" ||
              field.excel_field.toString().toLowerCase().includes("name")) &&
            usedStandardTempFields["name"] == false
          ) {
            field.template_id = this.standardTemp._id;
            field.template_filed_id = "name";
            field.inputType = "SINGLE_LINE_TEXT";
            usedStandardTempFields["name"] = true;
          } else if (
            field &&
            field.excel_field &&
            field.excel_field.includes("mail") &&
            usedStandardTempFields["email"] == false
          ) {
            field.template_id = this.standardTemp._id;
            field.template_filed_id = "email";
            field.inputType = "SINGLE_LINE_TEXT";
            usedStandardTempFields["email"] = true;
          }
        });
      } else {
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "formTemplates"
        ].forEach((template) => {
          if (
            template.type == "STANDARD" &&
            template.name == "Contact details"
          ) {
            this.excelFileHeadersApplication[
              this.activeNameForApplicationImport
            ]["formTemplates"].splice(
              this.excelFileHeadersApplication[
                this.activeNameForApplicationImport
              ]["formTemplates"].indexOf(template),
              1
            );
          }
        });
        this.excelFileHeadersApplication[this.activeNameForApplicationImport][
          "columnHeaders"
        ].forEach((field) => {
          if (
            field.template_id?.toString() === this.standardTemp._id.toString()
          ) {
            field.template_id = "";
            field.template_filed_id = "";
          }
        });
      }
      this.showEntityTypes = true;
    },
    ShowTempDialog(index) {
      this.rowIndex = index;
      this.formTemplateName = "";
      this.centerDialogVisible = true;
    },
    addTemplate() {
      if (this.formTemplateName) {
        if (this.companyTemplates.length) {
          this.newFormTempCount++;
          let newTemp = {
            _id: `newTemplate#${this.formTemplateName}#${this.activeNameForApplicationImport}`,
            name: this.formTemplateName,
          };
          let isExisted;
          if (this.$route.query.workspace == undefined) {
            isExisted = this.companyTemplates.find((temp) => {
              if (
                temp.name &&
                newTemp.name &&
                temp.name.toLowerCase() === newTemp.name.toLowerCase()
              ) {
                return true;
              }
            });
          } else {
            isExisted = false;
          }
          if (isExisted || this.createdFormTemplate.includes(newTemp.name)) {
            this.newFormTempCount--;
            this.$notify.error({
              title: "Error",
              message: "Template name is already existed",
            });
          } else {
            this.createdFormTemplate.push(newTemp.name);
            this.excelFileHeadersApplication[
              this.activeNameForApplicationImport
            ]["formTemplates"].push(newTemp);
            for (
              let i = this.rowIndex;
              i <
              this.excelFileHeadersApplication[
                this.activeNameForApplicationImport
              ]["columnHeaders"].length;
              i++
            ) {
              this.excelFileHeadersApplication[
                this.activeNameForApplicationImport
              ]["columnHeaders"][i].template_id = newTemp._id;
            }
            this.centerDialogVisible = false;
          }
        } else {
          this.newFormTempCount++;
          let newTemp = {
            _id: `newTemplate#${this.formTemplateName}#${this.activeNameForApplicationImport}`,
            name: this.formTemplateName,
          };
          this.createdFormTemplate.push(newTemp.name);
          this.excelFileHeadersApplication[this.activeNameForApplicationImport][
            "formTemplates"
          ].push(newTemp);
          this.centerDialogVisible = false;
        }
      } else {
        return;
      }
    },
    getTemplateFields(row) {
      if (
        row &&
        row.template_id &&
        this.companyTemplates &&
        this.companyTemplates.length
      ) {
        let foundedTemp = this.companyTemplates.find((temp) => {
          if (
            temp &&
            temp._id &&
            row.template_id &&
            temp._id == row.template_id
          ) {
            return true;
          } else {
            return false;
          }
        });
        let contentFields = [
          "HEADING",
          "PARAGRAPH",
          "HTML_CONTENT",
          "SINGLE_LINE_CONTENT",
          "VIDEO",
          "ACTION_BUTTON",
          "HORIZONTAL_LINE",
          "DIVISION",
        ];
        if (
          foundedTemp &&
          foundedTemp.sections[0] &&
          foundedTemp.sections[0].fields
        ) {
          let fields = [];
          foundedTemp.sections[0].fields.forEach((field) => {
            if (
              field &&
              field.input_type &&
              contentFields.includes(field.input_type) === false
            ) {
              fields.push(field);
            }
          });

          return fields.map((e) => {
            e.template_id = row.template_id;
            return e;
          });
        } else {
          return [];
        }
      }
      return [];
    },
    checkIsFieldSelected(field) {
      return this.excelFileHeadersApplication[
        this.activeNameForApplicationImport
      ]["columnHeaders"].find(
        (e) =>
          e.template_id == field.template_id && e.template_filed_id == field.key
      )
        ? true
        : false;
    },
    checkEntityIsSelected(field) {
      let exist = this.excelFileHeadersApplication[
        this.activeNameForApplicationImport
      ]["columnHeaders"].find(
        (e) =>
          e.inputType == "ENTITY" &&
          e.entity_id?.toString() == field.value?.toString()
      );
      return exist ? true : false;
    },
    async getAllCompanyEntities() {
      // await this.$store.dispatch("entities/fetchEntities", { get_all: true });
      // if (this.getAllEntities && this.getAllEntities.data) {
      //     this.companyEntities = this.getAllEntities.data
      // }
      this.companyEntities = await this.fetchAllEntities(false);
    },
    handleCloseDialog() {
      this.$router.push("/entity");
    },
    gotoDashboard() {
      this.mappingStatusDialogVisible = false;
      this.$router.push("/entity");
    },
    async getCompanyUserType(activeRole) {
      try {
        await this.$store.dispatch("auth/getUserTypeById", activeRole);
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async goToNewWorkspace() {
      // this.$router.push("/entity");
      this.mappingStatusDialogVisibleNewCompany = false;
      await this.changeWorkspace(this.getAppImportData.data.companyId);
    },
    handleGroupChange() {
      this.showGroups = false;
      this.showGroups = true;
    },
    disableEntityVariableButton() {
      if (
        this.entityVariableField.relationship_key == "" ||
        this.entityVariableField.entityVariableInfo == ""
      ) {
        return true;
      }
      return false;
    },
    handleActiveChange(target) {
      if (target < this.active) {
        switch (target) {
          case 0:
            break;
          case 1:
            break;
        }
        this.active = target;
      } else if (!this.checkIsNewWorkspace() && target == 2) {
        this.$message.warning(
          "Can't config workspace settings while creating application in same workspace"
        );
      } else {
        this.$message.warning("Please click next");
      }
    },
    getWorkspaceName() {
      return this.$route.query.workspace
        ? this.$route.query.workspace
        : this.getCompanyDetails?.name;
    },
    async onUpdate() {
      //Logo will uploaded from the backend
      this.logoDialog = false;
    },
    checkIsNewWorkspace() {
      return this.$route.query.workspace;
    },
    resetCompanyForm() {
      this.company_form = {
        name: this.getWorkspaceName(),
        logo: "",
        about_company_text: "",
        dialog_header_color: "#3A065C",
        dialog_header_text_color: "#FFFFFF",
        sidemenu_background: "#FFFFFF",
        sidemenu_text: "#000000",
        menubackground: "#E7DFDF",
        menuType: "Vertical",
        menuIcon: true,
      };
      this.imgData = "";
      if (this.getCompanyDetails) {
        this.company_form = {
          ...this.company_form,
          // logo: this.getCompanyDetails.logo,
          dialog_header_color: this.getCompanyDetails.dialog_header_color,
          dialog_header_text_color:
            this.getCompanyDetails.dialog_header_text_color,
          sidemenu_background: this.getCompanyDetails.sidemenu_background,
          sidemenu_text: this.getCompanyDetails.sidemenu_text,
          styled_sidemenu: this.getCompanyDetails.styled_sidemenu,
          menubackground: this.getCompanyDetails.menubackground,
          menuType: this.getCompanyDetails.menuType || "Vertical",
          application_background_color:
            this.getCompanyDetails.application_background_color || "#fffffff",
        };
        if (this.getCompanyDetails.styled_sidemenu) {
          this.isColorFilled = true;
        }
      }
    },
    getPlaceHolder() {
      return `you have ${5 - this.currentResponseIndex} attempts left`;
    },
    checkNextDisabled(isChanges) {
      if (isChanges)
        return (
          this.cardLoading ||
          !this.responseCaptured ||
          this.responseArray.length == 6
        );
      return this.cardLoading || !this.responseCaptured;
    },
  },
  computed: {
    layoutClass() {
      return this.company_form.menuType === "Horizontal"
        ? "horizontal"
        : "vertical";
    },
    containerWidth() {
      if (this.company_form.menuType === "Horizontal") {
        return this.company_form.menuIcon ? "127%" : "122%";
      } else {
        return "80%";
      }
    },
    secondContainerWidth() {
      return this.company_form.menuType === "Horizontal" ? "70%" : "150%";
    },
    isIconEnabled() {
      return this.changes_prompt && !this.cardLoading;
    },
    ...mapGetters("entityGroups", [
      "getAllEntityGroupsData",
      "getSelectedGroupsData",
      "getSingleEntityGroupData",
    ]),
    ...mapGetters("entities", [
      "getstoreUploadedFileStatus",
      "getExcelFileHeadersApplication",
      "getAllEntities",
      "getEntityDataById",
    ]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("apps", [
      "getAllEntitiesPreviewMappingData",
      "getAppImportData",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getAuthenticationStatus",
      "getActiveWorkspace",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),

    ...mapGetters("company", ["getCompanyDetails", "getCompanyExistsStatus"]),
  },
  async mounted() {
    this.resetCompanyForm();
    this.session_id = `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`;
    this.application_prompt = this.$route.query?.workspace
      ? `Create application for ${this.$route.query.workspace}.`
      : " ";
    await this.getAllCompanyEntities();
  },
  components: {
    SuccessAndErrorsTable,
    loadingComponentVue,
    vueAnkaCropper,
  },
};
</script>
<style lang="scss">
.match_upload_button {
  height: 10vh;
  border: 1.5px solid #f754a2;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: #f754a2;
  background-color: white;
}

.uploaded-file-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  width: 36vw;
  height: 8vh;
}
.icon2 {
  width: 24px;
  height: auto;

  .primary {
    fill: var(--primary-color) !important;
  }
  .stroke {
    stroke: var(--primary-color) !important;
    fill: #fff !important;
  }
}
</style>
<style scoped>
.horizontal .scrollable-list-icon {
  overflow-x: auto;
  white-space: nowrap;
}
.loading-container {
  position: fixed;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.file-upload {
  margin-bottom: 150px;
}
.icons-in-application ::v-deep .dialog-content {
  scrollbar-width: none;
}
.login-signup {
  margin-top: 4px;
}
.login-signup .el-button {
  padding: 8px 14px;
}
.footer {
  padding-right: 23px;
}
.sections {
  margin-bottom: 50px;
}
.generate-icon-disabled {
  position: absolute;
  top: 42%;
  left: 56%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  cursor: not-allowed;
  opacity: 0.5;
}
.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 99%;
  background-color: #eee;
  border-top: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.Regenrate-Footer {
  margin-right: 350px;
}
.upload-icon {
  margin-left: 130px;
}
.input-buttons-container {
  padding: 10px 10px;
}
.rounded {
  border-radius: 1.25rem !important;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.generate-button {
  margin-top: 15px;
}
.table-in-preview {
  border: 1px solid #dcdfe6;
  margin: 20px;
  height: 300px;
  background-color: white;
}
.vertical .sidebar-in-preview {
  width: 32%;
  height: auto;
  border-right: 1px solid #dcdfe6;
}
.vertical .containerr {
  display: flex;
}
.horizontal .sidebar-in-preview {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dcdfe6;
}
.horizontal .sidebar-in-preview ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}
.horizontal .containerr {
  display: flex;
  flex-direction: column;
}

.sidebar-in-preview ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar-in-preview li {
  display: flex;
  align-items: center;
  padding: 10px;
}

.sidebar-in-preview li span {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.underline {
  width: 100%;
  border: 1px solid #dcdfe6;
}
.second-container {
  margin: 20px;
  border: 1px solid #ccc;
}
.colors-ProfileSettings {
  display: flex;
  flex-direction: column;
}
.colors-ProfileSettings el-color-picker {
  margin-left: auto;
}
.color-square {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #ccc;
}
.color-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.color-item span {
  margin-right: 10px;
}
.colors-ProfileSettings {
  font-size: 14px;
}
.items-container ::v-deep .el-collapse-item__header {
  height: 60px !important;
  font-size: 15px !important;
}
.input-in-profile {
  font-size: 14px;
}
.container {
  display: flex;
  justify-content: space-between;
}

.collapse-items {
  flex: 1;
  margin-left: 50px;
  border-right: 1px solid #dcdfe6;
}
.check-section {
  flex: 1;
}
.class-ai {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* margin : auto; */
}
.input-field {
  width: 100%;
}
.input-with-select {
  position: relative;
  display: inline-block;
  width: 30%;
  margin-right: 10px;
}
.generate-icon-enabled {
  position: absolute;
  top: 42%;
  left: 56%;
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 1;
}
.box-card {
  width: 50%;
  border: 0.5px dashed #dcdfe6;
}
.box-card2 {
  border: 0.5px dashed #dcdfe6;
  min-height: 430px;
  width: 380px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  scrollbar-width: thin;
  overflow-y: auto;
  height: 59vh;
}
.footer .all-responses {
  position: absolute;
  bottom: 12px;
  left: 375px;
}
.message-ex {
  text-align: left;
  font-size: 13px;
  margin: 12px 314px;
}
.step-class .icon-class {
  cursor: pointer;
}
.icon-class {
  cursor: pointer;
}

@media (max-width: 768px) and (min-width: 570px) {
  .fixed-footer {
    padding: 5px;
  }
  .footer {
    padding-right: 10px;
  }
  .input-with-select {
    width: 50%;
    margin-left: 40px;
  }
  .message-ex {
    margin: 12px 194px;
  }
  .Regenrate-Footer {
    margin-right: 0px;
  }
  .generate-icon-enabled,
  .generate-icon-disabled {
    top: 62%;
    left: 61%;
    transform: translate(-50%, -50%);
  }
  .footer .all-responses {
    position: absolute;
    bottom: 12px;
    left: 10px;
  }
}

@media (max-width: 576px) and (min-width: 320px) {
  .footer .all-responses {
    position: absolute;
    bottom: 12px;
  }
  .input-with-select {
    width: 90%;
  }
  .Regenrate-Footer {
    margin-right: 80px;
  }
  .generate-icon-enabled,
  .generate-icon-disabled {
    top: 30%;
    left: 83%;
    transform: translate(-50%, -50%);
  }
  .message-ex {
    margin: 0px;
  }
  .box-card {
    width: 90%;
  }
  .el-step__title .is-process {
    font-size: 13px !important;
  }
}
</style>
